<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="notification.new.title"></app-i18n>
          </h2>
          <div class="grid grid-cols-12 mt-5">
            <label for="modal-form-3" class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2">
              {{ i18n('notification.fields.image') }}
            </label>
            <div class="preview lg:col-span-8 col-span-12 lg:mt-0 mt-2">
              <Dropzone
                ref-key="dropzoneRef"
                id="dropzoneRef"
                :options="{
                  url: 'https://httpbin.org/post',
                  paramName: 'file',
                  thumbnailWidth: 150,
                  maxFilesize: 0.5,
                  // clickable: !isMaxUploaded,
                  addRemoveLinks: true,
                  maxFiles: 1,
                  headers: { 'My-Awesome-Header': 'header value' }
                }"
                class="dropzone"
              >
                <div class="text-lg font-medium">
                  {{ i18n('settings.shippingTab.modal.fileUploaderHint') }}
                </div>
              </Dropzone>
            </div>
          </div>
          <div v-if="model">
            <div class="grid grid-cols-12 mt-5">
              <h4 class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2">
                {{ i18n('notification.fields.title') }}
              </h4>
              <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                <input
                  id="title-en"
                  type="text"
                  class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                  v-model="model.title.en"
                  :placeholder="i18n('notification.fields.english')"
                />
              </div>
              <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2" :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'">
                <input
                  id="title-ar"
                  type="text"
                  class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                  v-model="model.title.ar"
                  :placeholder="i18n('notification.fields.arabic')"
                />
              </div>
            </div>
            <div class="grid grid-cols-12 mt-5">
              <h4 class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2">
                {{ i18n('notification.fields.body') }}
              </h4>
              <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                <textarea
                  id="body-en"
                  :rows="4"
                  class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                  v-model="model.body.en"
                  :placeholder="i18n('notification.fields.english')"
                ></textarea>
              </div>
              <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2" :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'">
                <textarea
                  id="body-ar"
                  :rows="4"
                  class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                  v-model="model.body.ar"
                  :placeholder="i18n('notification.fields.arabic')"
                ></textarea>
              </div>
            </div>
            <div class="grid grid-cols-12 mt-5">
              <h4 class="text-lg font-medium leading-none items-center lg:col-span-2">
                {{ i18n('notification.create.userType') }}
              </h4>
              <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                <TomSelect v-model="model.userType" class="w-full">
                  <option value="chef" class="">
                    {{ i18n('notification.create.chef') }}</option>
                  <option value="captain">
                    {{ i18n('notification.create.captain') }}</option>
                  <option value="client">
                    {{ i18n('notification.create.client') }}</option>
                </TomSelect>
              </div>
            </div>
            <div class="flex justify-center items-center mt-10">
              <AppButton
                type="button"
                class="btn bg-theme-36 text-white cursor-pointer"
                :class="!saveLoading ? 'w-24' : ''"
                :disabled="saveLoading || uploadLoading"
                :loading="saveLoading"
                @click="doSubmit()"
              >
                <strong>{{ i18n('common.save') }}</strong>
                <template v-slot:loading>
                  <app-i18n code="common.loading"></app-i18n>
                  <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px;" />
                </template>
              </AppButton>
              <button type="button" class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer" :disabled="saveLoading || uploadLoading" @click="doCancel()">
                <app-i18n code="common.cancel"></app-i18n>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, provide } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Dropzone from 'dropzone'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { NotificationModel } from '@/store/notification/notification-model'

const { fields } = NotificationModel
const formSchema = new FormSchema([
  fields.title,
  fields.body,
  fields.image,
  fields.userType
])

export default defineComponent({
  setup() {
    const errorMessage = ref('')
    let uploadLoading = ref(false)
    const dropzoneRef = ref()
    const filesToSave = ref([])
    provide('bind[dropzoneRef]', el => {
      dropzoneRef.value = el
    })
    const clearDropzone = () => {
      Dropzone.forElement('#dropzoneRef').removeAllFiles(true)
      while (filesToSave.value.length > 0) {
        filesToSave.value.pop()
      }
    }
    const getDropZoneInstance = () => {
      return dropzoneRef.value
    }
    const setupDropZone = () => {
      const elDropzoneRef = getDropZoneInstance()
      elDropzoneRef.dropzone.on('success', file => {
        filesToSave.value.push(file)
      })

      elDropzoneRef.dropzone.on('removedfile', file => {
        filesToSave.value.pop()
      })

      elDropzoneRef.dropzone.on('complete', () => {
        uploadLoading = false
      })

      elDropzoneRef.dropzone.on('uploadprogress', () => {
        uploadLoading = true
      })
      // elDropzoneRef.dropzone.on('error', () => {
      //   const errorMessage = $i18n.locale == 'ar' ? 'لا مزيد من الملفات' : 'No more files please!'
      //   alert(errorMessage)
      // })
    }
    onMounted(() => {
      setupDropZone()
    })
    return {
      setupDropZone,
      uploadLoading,
      filesToSave,
      getDropZoneInstance,
      clearDropzone,
      errorMessage
    }
  },
  data() {
    return {
      model: null,
      rules: formSchema.rules()
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'notification/form/record',
      saveLoading: 'notification/form/saveLoading'
    })
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.notifications')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.notifications')
    }
  },
  async mounted() {
    this.doNew()
    this.model = formSchema.initialValues(this.record || {})
  },
  methods: {
    ...mapActions({
      doNew: 'notification/form/doNew',
      doSendToAll: 'notification/form/doSendToAll'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (var key in this.model) {
        var value = this.model[key]
        for (var groupKey in value) {
          var value2 = value[groupKey]
          if (value2.length == 0) {
            this.errorMessage = 'notifications.create.popup.emptyFields'
            return false
          }
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/notification')
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const { userType, ...values } = formSchema.cast(this.model)
      await this.doSendToAll({
        notification: values,
        image: this.filesToSave.length ? this.filesToSave[0] : null,
        userType: userType || 'chef'
      })
    }
  }
})
</script>
<style lang="scss">
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
